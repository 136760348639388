import React from "react";
import styles from "./Slider.module.css";
import { Range } from "react-range";

const Slider = ({ value, handleChange }) => {
  return (
    <section className={styles.container}>
      <Range
        min={100000}
        max={1800000}
        values={[value]}
        step={10000}
        onChange={(e) => handleChange(e)}
        renderTrack={({ props, children }) => (
          <div {...props} className={styles.track}>
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className={styles.thumb}
            aria-label="Overskudd i næring"
          />
        )}
      />
    </section>
  );
};

export default Slider;
