import Big from "big.js";

/**
 * Keeps all the general calculation constants in an object.
 * Values that need to be calculated based on other values have empty strings.
 * Their calculations follow below.
 */

let satser = {
  skatt_alminnelig_inntekt: 0.22,
  personfradrag: {
    klasse_1: 58250,
    klasse_2: 0,
  },
  trinnskatt: {
    trinn_1: {
      innslag: 190350,
      storrelse: "",
      sats: 0.017,
    },
    trinn_2: {
      innslag: 267900,
      storrelse: "",
      sats: 0.04,
    },
    trinn_3: {
      innslag: 643800,
      storrelse: "",
      sats: 0.134,
    },
    trinn_4: {
      innslag: 969200,
      sats: 0.164,
    },
  },
  trygdeavgift: {
    lonnsinntekt: 0.08,
    naeringsinntekt: 0.112,
    pensjonsinntekt: 0.051,
    nedre_grense_for_trygdeavgift: 64650,
    maks_av_grunnlaget: 0.25,
  },
  minstefradrag_lonn: {
    sats: 0.46,
    ovre_grense: 109950,
    nedre_grense: 4000,
    saerskilt_fradrag_arbeidsinntekt: 31800,
  },
  grunnbelop_i_folketryden: 111477,
  bruttolonn: {
    under_resultatbonus: "",
    under_resultatbonus_foer_lonn: "",
    G6: "",
    G6_foer_lonn: "",
    G7_1: "",
    G7_1_foer_lonn: "",
    G12: "",
    G12_foer_lonn: "",
  },
  terskel_innslag_resultatbonus: 1.6,
  innslagspunkt_for_bonus: "",
  innslagspunkt_for_bonus_sats: 0.02,
  innslagspunkt_for_bonus_brutto_FP: "",
  innslagspunkt_for_bonus_brutto_FP_sats: "",
  tillegstrygd_sykepenger_NAV: 0.095,
  forsikring_ansvar_sats_AS: 0.011,
  forsikring_maks_pris_AS: "",
  pensjon: {
    vippepunkt_hoy_sats: "",
    lav_sats: 0.034,
    hoy_sats: 0.215,
    skatt_uttak: 0.2,
    skattesats_ENK: 0.04,
  },
  faktor: {
    lonn_inkl_fp_og_bonus_under: +Big(1).div(1.141).div(1.034).times(0.925),
    lonn_inkl_fp_under_7_1G: "",
    lonn_inkl_fp_fra_7_1G_til_12G: "",
    lonn_inkl_fp_over_12G: "",
  },
  eget_AS: {
    bruttolonn: {
      G7_1: "",
      G7_1_foer_lonn: "",
      G12: "",
      G12_foer_lonn: "",
    },
    faktor: {
      lonn_inkl_fp_under_7_1_G: +Big(1).div(1.141).div(1.034),
      lonn_inkl_fp_fra_7_1G_til_12G: +Big(1).div(1.141).div(1.215),
      lonn_inkl_fp_over_12G: +Big(1).div(1.141),
    },
  },
};

satser.trinnskatt.trinn_1.storrelse = +Big(
  satser.trinnskatt.trinn_2.innslag
).minus(satser.trinnskatt.trinn_1.innslag);

satser.trinnskatt.trinn_2.storrelse = +Big(
  satser.trinnskatt.trinn_3.innslag
).minus(satser.trinnskatt.trinn_2.innslag);

satser.trinnskatt.trinn_3.storrelse = +Big(
  satser.trinnskatt.trinn_4.innslag
).minus(satser.trinnskatt.trinn_3.innslag);

satser.bruttolonn.under_resultatbonus = +Big(satser.grunnbelop_i_folketryden)
  .times(satser.terskel_innslag_resultatbonus)
  .times(satser.faktor.lonn_inkl_fp_og_bonus_under);

satser.bruttolonn.G6 = +Big(satser.grunnbelop_i_folketryden).times(6);
satser.bruttolonn.G7_1 = +Big(satser.grunnbelop_i_folketryden).times(7.1);
satser.bruttolonn.G12 = +Big(satser.grunnbelop_i_folketryden).times(12);

satser.innslagspunkt_for_bonus = +Big(satser.grunnbelop_i_folketryden).times(
  satser.terskel_innslag_resultatbonus
);

satser.forsikring_maks_pris_AS = +Big(satser.bruttolonn.G6).times(
  satser.forsikring_ansvar_sats_AS
);

satser.pensjon.vippepunkt_hoy_sats = +Big(satser.bruttolonn.G7_1)
  .div(0.700038)
  .div(1.12);

satser.innslagspunkt_for_bonus_brutto_FP_sats = +Big(2).div(78.4);

satser.faktor.lonn_inkl_fp_under_7_1G = +Big(1)
  .div(1.141)
  .div(1.034)
  .times(1 + satser.innslagspunkt_for_bonus_brutto_FP_sats)
  .times(0.925);

satser.faktor.lonn_inkl_fp_fra_7_1G_til_12G = +Big(1)
  .div(1.141)
  .div(1.215)
  .times(1 + satser.innslagspunkt_for_bonus_brutto_FP_sats)
  .times(0.925);

satser.faktor.lonn_inkl_fp_over_12G = +Big(1)
  .div(1.141)
  .times(1 + satser.innslagspunkt_for_bonus_brutto_FP_sats)
  .times(0.925);

satser.innslagspunkt_for_bonus_brutto_FP = +Big(
  satser.innslagspunkt_for_bonus
).times(satser.faktor.lonn_inkl_fp_og_bonus_under);

satser.bruttolonn.under_resultatbonus_foer_lonn = +Big(
  satser.grunnbelop_i_folketryden
).times(satser.terskel_innslag_resultatbonus);

satser.bruttolonn.G6_foer_lonn = +Big(satser.bruttolonn.G6).div(
  satser.faktor.lonn_inkl_fp_under_7_1G
);

satser.bruttolonn.G7_1_foer_lonn = +Big(satser.bruttolonn.G7_1).div(
  satser.faktor.lonn_inkl_fp_under_7_1G
);

satser.bruttolonn.G12_foer_lonn = +Big(satser.bruttolonn.G7_1_foer_lonn).plus(
  Big(Big(satser.bruttolonn.G12).minus(satser.bruttolonn.G7_1)).div(
    satser.faktor.lonn_inkl_fp_fra_7_1G_til_12G
  )
);

satser.eget_AS.bruttolonn.G7_1 = satser.bruttolonn.G7_1;
satser.eget_AS.bruttolonn.G12 = satser.bruttolonn.G12;

satser.eget_AS.bruttolonn.G7_1_foer_lonn = +Big(
  satser.eget_AS.bruttolonn.G7_1
).div(satser.eget_AS.faktor.lonn_inkl_fp_under_7_1_G);

satser.eget_AS.bruttolonn.G12_foer_lonn = +Big(
  satser.eget_AS.bruttolonn.G7_1_foer_lonn
).plus(
  Big(
    Big(satser.eget_AS.bruttolonn.G12).minus(satser.eget_AS.bruttolonn.G7_1)
  ).div(satser.eget_AS.faktor.lonn_inkl_fp_fra_7_1G_til_12G)
);

export default satser;
