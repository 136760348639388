import React, { useState } from "react";
import styles from "./As.module.css";
import satser from "../lib/satser_2022";
import Big from "big.js";
import AmountRow from "./Blocks/AmountRow";
import ShowDetailsButton from "./Blocks/ShowDetailsButton";
import PieChart from "./Charts/PieChart";
import { chartColors } from "../lib/common";
import InfoButton from "./Blocks/InfoButton";

const As = ({
  overskudd_i_naering,
  lonnsintekt_andre_arb_giv,
  employTotal,
  className,
  displayModal,
}) => {
  const [forsikring, setForsikring] = useState(-15000);
  const [adm_regnskap, setAdm_regnskap] = useState(-30000);

  const [isForsikringEnabled, setIsForsikringEnabled] = useState(true);
  const [isAdm_RegnskapEnabled, setIsAdm_RegnskapEnabled] = useState(true);
  const [isSykelonnEnabled, setIsSykelonnEnabled] = useState(true);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);

  //
  // CALCULATIONS
  //

  const sykelonn = +Big(
    Big(
      Big(overskudd_i_naering)
        .plus(isForsikringEnabled ? +forsikring : 0)
        .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
    )
      .times(satser.eget_AS.faktor.lonn_inkl_fp_under_7_1_G)
      .times(satser.forsikring_ansvar_sats_AS) > satser.forsikring_maks_pris_AS
      ? satser.forsikring_maks_pris_AS
      : Big(
          Big(overskudd_i_naering)
            .plus(isForsikringEnabled ? +forsikring : 0)
            .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
        )
          .times(satser.eget_AS.faktor.lonn_inkl_fp_under_7_1_G)
          .times(satser.forsikring_ansvar_sats_AS)
  ).times(-1);

  const overskudd_etter_kostnader = +Big(overskudd_i_naering)
    .plus(isForsikringEnabled ? +forsikring : 0)
    .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
    .plus(isSykelonnEnabled ? sykelonn : 0);

  const bruttolonn_under_7_1G =
    overskudd_etter_kostnader > satser.eget_AS.bruttolonn.G7_1_foer_lonn
      ? +Big(satser.eget_AS.bruttolonn.G7_1_foer_lonn).times(
          satser.eget_AS.faktor.lonn_inkl_fp_under_7_1_G
        )
      : +Big(overskudd_etter_kostnader).times(
          satser.eget_AS.faktor.lonn_inkl_fp_under_7_1_G
        );

  const bruttolonn_under_7_1G_pensjon = +Big(bruttolonn_under_7_1G).times(
    satser.pensjon.lav_sats
  );

  const bruttolonn_mellom_7_1G_12G =
    overskudd_etter_kostnader < satser.eget_AS.bruttolonn.G7_1_foer_lonn
      ? 0
      : overskudd_etter_kostnader > satser.eget_AS.bruttolonn.G12_foer_lonn
      ? +Big(satser.eget_AS.bruttolonn.G12).minus(
          satser.eget_AS.bruttolonn.G7_1
        )
      : +Big(
          Big(overskudd_etter_kostnader).minus(
            satser.eget_AS.bruttolonn.G7_1_foer_lonn
          )
        ).times(satser.eget_AS.faktor.lonn_inkl_fp_fra_7_1G_til_12G);

  const bruttolonn_mellom_7_1G_12G_pensjon = +Big(
    bruttolonn_mellom_7_1G_12G
  ).times(satser.pensjon.hoy_sats);

  const bruttolonn_over_12G =
    overskudd_etter_kostnader < satser.eget_AS.bruttolonn.G12_foer_lonn
      ? 0
      : +Big(
          Big(overskudd_etter_kostnader).minus(
            satser.eget_AS.bruttolonn.G12_foer_lonn
          )
        ).times(satser.eget_AS.faktor.lonn_inkl_fp_over_12G);

  const bruttolonn_over_12G_pensjon = 0;

  const bruttolonn_sum = +Big(bruttolonn_under_7_1G)
    .plus(bruttolonn_mellom_7_1G_12G)
    .plus(bruttolonn_over_12G);

  const bruttolonn_sum_pensjon = +Big(bruttolonn_under_7_1G_pensjon)
    .plus(bruttolonn_mellom_7_1G_12G_pensjon)
    .plus(bruttolonn_over_12G_pensjon);

  const pensjonssparing = bruttolonn_sum_pensjon;

  const lonnsinntekt_eget_as = bruttolonn_sum;

  const grunnlag_AGA = +Big(bruttolonn_sum_pensjon).plus(lonnsinntekt_eget_as);

  const sum_inntekt = +Big(lonnsinntekt_eget_as).plus(
    lonnsintekt_andre_arb_giv
  );

  const minstefradrag_helper = +Big(sum_inntekt).times(
    satser.minstefradrag_lonn.sats
  );

  const minstefradrag =
    minstefradrag_helper <
    satser.minstefradrag_lonn.saerskilt_fradrag_arbeidsinntekt
      ? sum_inntekt
      : minstefradrag_helper > satser.minstefradrag_lonn.ovre_grense
      ? satser.minstefradrag_lonn.ovre_grense
      : minstefradrag_helper;

  const grunnlag_skatt = +Big(sum_inntekt).minus(minstefradrag);

  const personfradrag =
    +Big(sum_inntekt).minus(minstefradrag_helper) <
    satser.personfradrag.klasse_1
      ? 0
      : satser.personfradrag.klasse_1;

  const alminnelig_inntekt = +Big(sum_inntekt)
    .minus(minstefradrag)
    .minus(personfradrag);

  const trygdeavgift_grunnlag =
    +Big(sum_inntekt).minus(satser.trygdeavgift.nedre_grense_for_trygdeavgift) >
    0
      ? +Big(sum_inntekt).minus(
          satser.trygdeavgift.nedre_grense_for_trygdeavgift
        )
      : 0;

  const trygdeavgift_25p_av_belop_over_nedre_grense = +Big(
    trygdeavgift_grunnlag
  ).times(satser.trygdeavgift.maks_av_grunnlaget);

  const trygdeavgift_beregning =
    sum_inntekt > satser.trygdeavgift.nedre_grense_for_trygdeavgift
      ? +Big(sum_inntekt).times(satser.trygdeavgift.lonnsinntekt)
      : 0;

  const trygdeavgift_lonn =
    trygdeavgift_beregning > trygdeavgift_25p_av_belop_over_nedre_grense
      ? trygdeavgift_25p_av_belop_over_nedre_grense
      : trygdeavgift_beregning;

  const skatt_alminnelig_inntekt =
    personfradrag < satser.personfradrag.klasse_1
      ? 0
      : +Big(alminnelig_inntekt).times(satser.skatt_alminnelig_inntekt);

  const trinnskatt_over_innslagspunkt = (trinn) =>
    +Big(sum_inntekt).minus(satser.trinnskatt[`trinn_${trinn}`].innslag);

  const trinnskatt_lonn_innenfor_trinn = (trinn) =>
    trinnskatt_over_innslagspunkt(trinn) >
    satser.trinnskatt[`trinn_${trinn}`].storrelse
      ? satser.trinnskatt[`trinn_${trinn}`].storrelse
      : trinnskatt_over_innslagspunkt(trinn);

  const trinnskatt_beregning = (trinn) =>
    +Big(trinnskatt_lonn_innenfor_trinn(trinn)).times(
      satser.trinnskatt[`trinn_${trinn}`].sats
    );

  const trinnskatt_for_trinn = (trinn) =>
    trinnskatt_beregning(trinn) >= 1 ? trinnskatt_beregning(trinn) : 0;

  const trinnskatt_sum = +Big(trinnskatt_for_trinn(1))
    .plus(trinnskatt_for_trinn(2))
    .plus(trinnskatt_for_trinn(3))
    .plus(trinnskatt_for_trinn(4));

  const skatt_lonnskostnader_AGA = +Big(grunnlag_AGA).times(0.141);

  const inntektsskatt_beregning = +Big(trygdeavgift_lonn)
    .plus(skatt_alminnelig_inntekt)
    .plus(trinnskatt_sum);

  const inntektsskatt_sats = +Big(inntektsskatt_beregning).div(
    sum_inntekt >= 1 ? sum_inntekt : 1
  );

  const inntektsskatt =
    inntektsskatt_beregning > 0 ? inntektsskatt_beregning : 0;

  const sum_skatt_inkl_AGA = +Big(inntektsskatt).plus(skatt_lonnskostnader_AGA);

  const netto = +Big(sum_inntekt)
    .minus(inntektsskatt_beregning)
    .plus(pensjonssparing);

  const admin_kostnader = +Big(
    Big(isForsikringEnabled ? +forsikring : 0)
      .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
      .plus(isSykelonnEnabled ? sykelonn : 0)
  ).times(-1);

  const aga_kostnader = +Big(lonnsinntekt_eget_as).times(0.141);

  const differanse_per_ar = +Big(netto).minus(employTotal);
  const differanse_per_maaned = +Big(differanse_per_ar).div(12);

  //
  // RENDER
  //

  return (
    <section className={`${styles.container} ${className || ""}`}>
      <h3 className={styles.header}>EGET AS</h3>

      <AmountRow name={`Dine penger`} amount={netto} isFinalAmount>
        <InfoButton
          infoText={
            "Sum nettolønn pluss brutto pensjonssparing (pensjonen beskattes ved uttak)"
          }
          displayModal={displayModal}
        />
      </AmountRow>
      <AmountRow name={`Differanse per år`} amount={differanse_per_ar} />
      <AmountRow name={`Differanse per måned`} amount={differanse_per_maaned} />

      {/* SHOW DETAILS */}

      <ShowDetailsButton
        isDetailViewOpen={isDetailViewOpen}
        handleClick={setIsDetailViewOpen}
      />

      {isDetailViewOpen && (
        <section className={styles.details__container}>
          <h4>Driftskostnader</h4>

          <section className={styles.input__container}>
            <label htmlFor="forsikring">
              <span>Forsikringer</span>
              <InfoButton
                infoText={"Reise, yrkesskade, behandling, fritidsulykke, liv"}
                displayModal={displayModal}
                aria-hidden="true"
              />
            </label>
            <section>
              <input
                type="number"
                id="forsikring"
                value={forsikring.toString().replace(/^0+/, "0")}
                onChange={(e) => setForsikring(e.target.value)}
                disabled={!isForsikringEnabled}
                max={0}
              />
              <input
                type="checkbox"
                checked={isForsikringEnabled}
                onChange={() => setIsForsikringEnabled((prev) => !prev)}
                title="Kryss av for å inkludere forsikring i beregninger"
              />
            </section>
          </section>

          <section className={styles.input__container}>
            <label htmlFor="adm_regnskap">Administrasjon og regnskap</label>
            <section>
              <input
                type="number"
                id="adm_regnskap"
                value={adm_regnskap.toString().replace(/^0+/, "0")}
                onChange={(e) => setAdm_regnskap(e.target.value)}
                disabled={!isAdm_RegnskapEnabled}
                max={0}
              />
              <input
                type="checkbox"
                checked={isAdm_RegnskapEnabled}
                onChange={() => setIsAdm_RegnskapEnabled((prev) => !prev)}
                title="Kryss av for å inkludere administrasjon og regnskap i beregninger"
              />
            </section>
          </section>

          <AmountRow
            name={`Sykelønn (100% fra fjerde dag)`}
            amount={sykelonn}
            withCheckbox
            checkBoxState={isSykelonnEnabled}
            onCheckboxClick={() => setIsSykelonnEnabled((prev) => !prev)}
            checkBoxLabel="Kryss av for å inkludere sykelønn i beregninger"
          />

          <hr />

          <AmountRow
            name={`Lønnsinntekt fra andre arbeidsgivere`}
            amount={lonnsintekt_andre_arb_giv}
          />
          <AmountRow
            name={`Lønnsinntekt - eget AS`}
            amount={lonnsinntekt_eget_as}
          />
          <AmountRow name={`Sum inntekt`} amount={sum_inntekt} />
          <AmountRow name={`Minstefradrag`} amount={minstefradrag} />
          <AmountRow name={`Grunnlag skatt`} amount={grunnlag_skatt} />
          <AmountRow name={`Sum personinntekt`} amount={alminnelig_inntekt} />

          <hr />

          <AmountRow name={`Trygdeavgift lønn`} amount={trygdeavgift_lonn} />
          <AmountRow name={`Trygdeavgift næring`} amount={0} />
          <AmountRow
            name={`Skatt på alminnelig inntekt`}
            amount={skatt_alminnelig_inntekt}
          />
          <AmountRow name={`Trinnskatt`} amount={trinnskatt_sum} />
          <AmountRow
            name={`Skatt på lønnskostnader (AGA)`}
            amount={skatt_lonnskostnader_AGA}
          />
          <AmountRow
            name={`Inntektsskatt (${
              Math.round(inntektsskatt_sats * 100) + "%"
            })`}
            amount={inntektsskatt_beregning}
          />
          <AmountRow name={`Sum skatt inkl AGA`} amount={sum_skatt_inkl_AGA} />
          <AmountRow name={`Pensjonssparing`} amount={pensjonssparing}>
            <InfoButton
              infoText={
                "Tilsvarende som i Employ (3.4% fra 0 - 7.1G, og 21.5% fra 7.1 - 12G)"
              }
              displayModal={displayModal}
            />
          </AmountRow>
        </section>
      )}

      {/* CHART */}

      <PieChart
        data={[
          { x: "Dine penger", y: netto, color: chartColors.blue },
          { x: "Skatt", y: inntektsskatt, color: chartColors.yellow },
          { x: "AGA", y: aga_kostnader, color: chartColors.purple },
          {
            x: "Driftskostnader",
            y: admin_kostnader,
            color: chartColors.green,
          },
        ]}
      />
    </section>
  );
};

export default As;
